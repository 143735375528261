import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotFoundPageComponent } from './main/pages/not-found-page/not-found-page.component'
import { authorizedGuard } from './auth/guards/authorized.guard'
import { SidebarLayoutComponent } from './main/layouts/sidebar-layout/sidebar-layout.component'

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth-routing.module').then(m => m.AuthRoutingModule),
  },
  {
    path: '',
    component: SidebarLayoutComponent,
    // children: [
    //   {
    //     path: '',
    //     loadChildren: () => import('./main/main-routing.module').then(m => m.MainRoutingModule),
    //   },
    //   {
    //     path: 'video',
    //     loadChildren: () => import('./video/video-routing.module').then(m => m.VideoRoutingModule),
    //   },
    // ],
    loadChildren: () => import('./main/main-routing.module').then(m => m.MainRoutingModule),
  },
  {
    path: 'admin',
    component: SidebarLayoutComponent, // for custom admin layout in future
    loadChildren: () => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule),
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    canActivate: [authorizedGuard],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
