import { animate, state, style, transition, trigger } from '@angular/animations'

export const animations = {
  enterFromRightWithDelay: trigger('enterFromRightWithDelay', [
    state('void', style({ transform: 'translateX(8px)', opacity: 0 })),
    state('*', style({ transform: 'translateX(0)', opacity: 1 })),
    transition(':enter', [animate('0.25s 0.125s ease-in-out')]),
  ]),
  enterFromBottom: trigger('enterFromBottom', [
    state('void', style({ transform: 'translateY(8px)', opacity: 0 })),
    state('*', style({ transform: 'translateY(0)', opacity: 1 })),
    transition(':enter', [animate('0.25s ease-in-out')]),
    transition(':leave', [animate('0.25s ease-in-out', style({ opacity: 0, transform: 'translateY(8px)' }))]),
  ]),
  fadeInOut: trigger('fadeInOut', [
    state('void', style({ opacity: 0 })),
    state('*', style({ opacity: 1 })),
    transition(':enter', [animate('0.25s ease-in-out')]),
    transition(':leave', [animate('0.25s ease-in-out', style({ opacity: 0 }))]),
  ]),
  fadeInOutFromTop: trigger('fadeInOutFromTop', [
    state('void', style({ transform: 'translateY(-8px)', opacity: 0 })),
    state('*', style({ transform: 'translateY(0)', opacity: 1 })),
    transition(':enter', [animate('0.25s ease-in-out')]),
    transition(':leave', [animate('0.25s ease-in-out', style({ opacity: 0 }))]),
  ]),
  fadeInOutFromRight: trigger('fadeInOutFromRight', [
    state('void', style({ transform: 'translateX(8px)', opacity: 0 })),
    state('*', style({ transform: 'translateX(0)', opacity: 1 })),
    transition(':enter', [animate('0.25s ease-in-out')]),
    transition(':leave', [animate('0.25s ease-in-out', style({ opacity: 0, transform: 'translateX(-8px)' }))]),
  ]),
  fadeInOutFromLeft: trigger('fadeInOutFromLeft', [
    state('void', style({ transform: 'translateX(-8px)', opacity: 0 })),
    state('*', style({ transform: 'translateX(0)', opacity: 1 })),
    transition(':enter', [animate('0.25s ease-in-out')]),
    transition(':leave', [animate('0.25s ease-in-out', style({ opacity: 0, transform: 'translateX(8px)' }))]),
  ]),
  fadeOutFast: trigger('fadeOutFast', [
    state('void', style({ opacity: 1 })),
    state('*', style({ opacity: 1 })),
    transition(':leave', [animate('0.15s ease-in-out', style({ opacity: 0 }))]),
  ]),
  fadeInFast: trigger('fadeInFast', [
    state('void', style({ opacity: 0 })),
    state('*', style({ opacity: 1 })),
    transition(':enter', [animate('0.15s ease-in-out')]),
  ]),
  delayedFadeInOutFromRight: trigger('delayedAbsFadeInOutFromRight', [
    transition(':enter', [
      style({ transform: 'translateX(8px)', opacity: 0 }),
      animate('250ms 350ms ease-out', style({ transform: 'translateX(0)', opacity: 1 })),
    ]),
    transition(':leave', [
      style({ transform: 'translateX(0%)', opacity: 1 }),
      animate('250ms ease-in', style({ transform: 'translateX(-8px)', opacity: 0 })),
    ]),
  ]),
}
