import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable, catchError } from 'rxjs'
import { environment } from 'src/app/environments/environment'
import { AuthService } from '../services/auth/auth.service'
import { AccountTokenResponse } from '../interfaces/account-token-response'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !request.url.startsWith(`${environment.baseApiUrl}`) ||
      request.url.startsWith(`${environment.baseApiUrl}/auth/`) ||
      (request.url === `${environment.baseApiUrl}/users/` && request.method === 'POST')
    )
      return next.handle(request)

    const token = (
      JSON.parse(
        localStorage.getItem(`${environment.appName.reverseDomainName}.${environment.accountCredentialsSuffix}`) ?? ''
      ) as AccountTokenResponse
    ).token

    if (!token) return next.handle(request)

    const clonedRequest = request.clone({
      headers: request.headers.set('Authorization', `Token ${token}`),
    })

    return next.handle(clonedRequest)
  }
}
