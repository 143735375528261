/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Injector } from '@angular/core'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { environment } from 'src/app/environments/environment'

@Injectable()
export class Snake2CamInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  convertCase(str: string, convertTo: 'camel' | 'snake'): string {
    if (convertTo === 'snake') {
      return str.replace(/([A-Z0-9])/g, '_$1').toLowerCase()
    }

    return str.replace(/_([a-z0-9])/g, g => g[1].toUpperCase())
  }

  convertObject(obj: any, convertTo: 'camel' | 'snake'): any {
    if (obj instanceof FormData) {
      // TODO: convert form data
      return obj
    }

    if (Array.isArray(obj)) {
      return obj.map(item => this.convertObject(item, convertTo))
    }

    if (typeof obj === 'object' && obj !== null) {
      const newObj: any = {}
      for (const key in obj) {
        const newKey = this.convertCase(key, convertTo)
        const newValue = this.convertObject(obj[key], convertTo)
        newObj[newKey] = newValue
      }
      return newObj
    }

    return obj
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip if the request is not from the api
    if (!req.url.startsWith(environment.baseApiUrl)) return next.handle(req)

    // convert request body from camel case to snake case
    if (req.body) {
      req = req.clone({ body: this.convertObject(req.body, 'snake') })
    }

    // convert response body from snake case to camel case
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body) {
          const body = this.convertObject(event.body, 'camel')
          return event.clone({ body })
        }
        return event
      })
    )
  }
}
