import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { animations } from 'src/app/ui/animations'
import { CardModule } from 'src/app/ui/card/card.module'
import { StepperComponent } from 'src/app/ui/stepper/stepper.component'
import { AiCreationProvideVideoLayoutComponent } from '../../layouts/ai-creation-provide-video-layout/ai-creation-provide-video-layout.component'
import { IconModule } from 'src/app/ui/icon/icon.module'
import {
  AiCreationVideoOptionsLayoutComponent,
  AiOptions,
} from '../../layouts/ai-creation-video-options-layout/ai-creation-video-options-layout.component'
import { VideoService } from '../../services/video.service'
import { OrganisationService } from '../../services/organisation/organisation.service'
import { catchError, switchMap, take } from 'rxjs'
import { ToastService } from 'src/app/ui/toast/toast.service'

@Component({
  selector: 'app-ai-creation-dialog',
  standalone: true,
  imports: [
    CardModule,
    CommonModule,
    StepperComponent,
    AiCreationProvideVideoLayoutComponent,
    IconModule,
    AiCreationVideoOptionsLayoutComponent,
  ],
  templateUrl: './ai-creation-dialog.component.html',
  styleUrl: './ai-creation-dialog.component.scss',
  animations: [animations.enterFromBottom, animations.delayedFadeInOutFromRight],
})
export class AiCreationDialogComponent {
  constructor(
    private video: VideoService,
    private organisationService: OrganisationService,
    private toast: ToastService
  ) {}

  @Output()
  public closeDialog: EventEmitter<void> = new EventEmitter<void>()

  public showDialog: boolean = true

  // 0: provide video (youtube url, upload video etc)
  // 1: fine tune (max clips, max duration, themes to base clips on)
  // TODO: template selection and settings (subtitles, template to select, etc)
  protected currentStep: number = 0

  protected aiOptions: AiOptions | null = null

  protected creationValid: boolean = false

  protected videoUrl: string | null = null

  @Input()
  public closeOverlay = () => {
    this.showDialog = false
  }

  protected attemptCloseDialog() {
    if (!this.showDialog) {
      this.closeDialog.emit()
    }
  }

  protected attemptNextStep() {
    if (this.currentStep < 2) {
      this.currentStep++
    } else {
      this.closeDialog.emit()
    }
  }

  protected canProceedToNextStep() {
    if (this.currentStep === 0) {
      return Boolean(this.videoUrl)
    }

    if (this.currentStep === 1) {
      return this.creationValid
    }

    return true
  }

  protected loading = false
  protected createVideos() {
    // this.closeOverlay()
    this.loading = true

    this.organisationService
      .getActiveOrganisation()
      .pipe(
        take(1),
        switchMap(organisation => {
          if (!organisation) {
            throw new Error('No active organisation')
          }

          if (!this.aiOptions) {
            throw new Error('AI options are not defined')
          }

          return this.video.createAiVideo(organisation.uuid, this.videoUrl!, this.aiOptions)
        }),
        catchError(err => {
          console.error(err)
          this.loading = false
          this.toast.error('Failed to create AI video')
          this.closeDialog.emit()
          return []
        })
      )
      .subscribe(() => {
        this.toast.success('AI video creating - check the render list in a few minutes for progress')
        this.loading = false
        this.closeDialog.emit()
      })
  }
}
