import { AccountInformation } from './account-information'

export interface AccountTokenResponse {
  account: AccountInformation
  token: string
}

export function isAccountTokenResponse(object: unknown): object is AccountTokenResponse {
  const accountTokenResponse = object as AccountTokenResponse
  return (
    accountTokenResponse.account !== undefined &&
    accountTokenResponse.account !== null &&
    accountTokenResponse.token !== undefined &&
    accountTokenResponse.token !== null
  )
}
