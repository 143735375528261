import { CommonModule } from '@angular/common'
import { Component, computed, HostBinding, Input, input } from '@angular/core'

@Component({
  selector: 'ui-stepper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent {
  public steps = input<number>(2)
  public currentStep = input<number>(0)
  public light = input<boolean>(false)

  public stepArray = computed(() => {
    return Array.from({ length: this.steps() }, (_, i) => i)
  })
}
