import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StatisticsDashboardPageComponent } from './pages/statistics-dashboard-page/statistics-dashboard-page.component'
import { StatisticsDashboardLayoutComponent } from './layouts/statistics-dashboard-layout/statistics-dashboard-layout.component'
import { StatisticsProcessingMinutesComponent } from './components/statistics-processing-minutes/statistics-processing-minutes.component'
import { UiModule } from '../ui/ui.module'

@NgModule({
  declarations: [
    StatisticsDashboardPageComponent,
    StatisticsDashboardLayoutComponent,
    StatisticsProcessingMinutesComponent,
  ],
  imports: [CommonModule, UiModule],
})
export class StatisticsModule {}
