<div class="mb-2 text-xs">
  @switch (validityState) { @case ('waiting') {
  <div class="flex gap-2 items-center">
    <div class="text-neutral-500">Please provide a YouTube URL</div>
  </div>
  } @case('invalid') {
  <div class="text-red-500 w-fit">Invalid video - please provide a different video</div>
  } @case('valid') {
  <div class="text-green-700">Video is valid</div>
  } @case ('pending') {
  <div class="text-neutral-500 flex gap-2">
    <svg class="animate-spin h-4 w-4 text-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    Checking video validity...
  </div>
  } }
</div>
<div class="mb-2">
  <input class="input text-sm px-1 py-0.5 w-full" [formControl]="urlControl" placeholder="YouTube URL" />
</div>
<div #youtubePlayer class="aspect-video h-[160px] w-full rounded-lg bg-neutral-100"></div>
