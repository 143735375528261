export const environment = {
  appName: {
    /**
     * The "pretty" name of the app. Used for specific display purposes.
     */
    pretty: 'ClipHog',
    /**
     * The name of the app. Used for display purposes.
     */
    name: 'cliphog',
    /**
     * Used for reverse domain name notation (local storage, etc)
     */
    reverseDomainName: 'com.cliphog',
  },
  /**
   * The base API URL. Used for all API requests.
   */
  baseApiUrl: 'https://api.cliphog.com',
  /**
   * The suffix for the account credentials key in local storage.
   * It is: (appName.reverseDomainName).(accountCredentialsSuffix)
   */
  accountCredentialsSuffix: 'accountCredentials',
  /**
   * The suffix for the active organisation key in local storage.
   * It is: (appName.reverseDomainName).(activeOrganisationSuffix)
   */
  activeOrganisationSuffix: 'activeOrganisation',
}
