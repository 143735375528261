<div class="flex items-center gap-4">
  <div class="w-9 h-9 rounded-full items-center justify-center flex bg-[#DFE4EC]">
    <!-- attribution to https://www.svgrepo.com/author/kudakurage/ -->
    <div
      class="w-5 h-5 transition-colors"
      [ngClass]="{
        'text-brand': activePanel === 'dashboard',
        'text-[#4D6080]': activePanel !== 'dashboard'
      }">
      <svg width="20" height="18" viewBox="0 0 20 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9768 6.4455L18.6955 8.93025C19.024 9.23475 18.9303 9.4695 18.4848 9.4695H16.4455V15.9615C16.4455 16.407 16.0705 16.8052 15.625 16.8052H12.0858V12.258C12.0858 11.8125 11.6875 11.4142 11.242 11.4142H9.01526C8.56976 11.4142 8.17151 11.8125 8.17151 12.258V16.8052H4.63226C4.18676 16.8052 3.78851 16.407 3.78851 15.9615V9.4695H1.77251C1.32701 9.4695 1.23326 9.23475 1.56176 8.93025L9.50726 1.7115C9.83576 1.407 10.3983 1.407 10.7493 1.7115L12.5538 3.3285V2.03925C12.5538 1.59375 12.952 1.1955 13.3975 1.1955H15.1555C15.601 1.1955 15.976 1.59375 15.976 2.03925L15.9768 6.4455Z"
          fill="currentColor"
          stroke="currentColor" />
      </svg>
    </div>
  </div>
  <span
    class="font-semibold"
    [ngClass]="{
      'text-[#0D2A4A]': activePanel !== 'dashboard',
      'text-[#042862]': activePanel === 'dashboard'
    }">
    Dashboard
  </span>
</div>
