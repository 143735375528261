import Konva from 'konva'
import { ComponentPreview, ComponentPreviewContext } from '../base'
import { StoryboardData } from 'src/app/main/services/video-api.service'
import { VideoComponentV0_0_0 } from 'src/app/main/interfaces/template/v0_0_0'

export const YouTubeUrlRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]+)/

export class YouTubePreview extends ComponentPreview {
  constructor(context: ComponentPreviewContext) {
    super(context)
  }

  public override createKonvaObject(): Konva.Shape | null {
    this.group = new Konva.Group({
      x: this.x,
      y: this.y,
      width: this.context.component.width,
      height: this.context.component.height,
    })

    const videoUrl = this.context.component.data['url'] as string | undefined
    if (!videoUrl) {
      this.setNoMediaImage()
    } else {
      // this is done asynchronously
      this.setStoryboard(videoUrl)
    }

    return this.shape
  }

  private cachedUrl: string | undefined
  private cachedScaleType: 'contain' | 'stretch' | 'contain-blur' | undefined
  private cachedStoryboards: { [url: string]: StoryboardData } = {}
  private stupidOverlay: Konva.Rect | null = null
  private group: Konva.Group | null = null
  private updated = false

  public override updateKonvaObject(): void {
    this.attemptUpdateScaleType()
    this.attemptUpdateStoryboard()
  }

  private redrawOverlay() {
    if (!this.stupidOverlay) return

    this.stupidOverlay.destroy()
    this.context.layer.draw()

    const { x, y, width, height } = this.getOverlayBounds()

    this.stupidOverlay = new Konva.Rect({
      x,
      y,
      width,
      height,
      fill: 'rgba(255, 255, 255, 0.3)',
      id: '__overlay',
    })

    this.context.layer.add(this.stupidOverlay)
    this.context.layer.draw()
  }

  private attemptUpdateScaleType() {
    const scaleType = this.context.component.data['scaleType'] as typeof this.cachedScaleType
    if (scaleType === this.cachedScaleType) return

    this.cachedScaleType = scaleType

    // Pretty goofy, refetches the storyboard to update the scale type.
    // Could probably do with rewriting now the logic is finished, but
    // i'll do that later lol
    if (this.shape && this.cachedUrl) {
      this.setStoryboard(this.cachedUrl)
    }
  }

  private attemptUpdateStoryboard() {
    const videoUrl = this.context.component.data['url'] as string | undefined

    if (videoUrl === this.cachedUrl) return

    if (!videoUrl || !YouTubeUrlRegex.test(videoUrl)) {
      this.setNoMediaImage()
      this.cachedUrl = undefined
      return
    }

    this.setStoryboard(videoUrl)
  }

  private setStoryboard(videoUrl: string) {
    this.setLoading()

    if (this.cachedStoryboards[videoUrl]) {
      this.cachedUrl = videoUrl
      this.setStoryboardImage(this.cachedStoryboards[videoUrl])
      return
    }

    this.context.videoService.getVideoStoryboard(videoUrl).subscribe(storyboard => {
      this.cachedStoryboards[videoUrl] = storyboard
      this.setStoryboardImage(storyboard)
      this.cachedUrl = videoUrl
    })
  }

  protected override onComponentSelected(component: VideoComponentV0_0_0): void {
    if (!this.shape) return
    if (component.id === this.context.component.id) {
      const { x, y, width, height } = this.getOverlayBounds()
      this.stupidOverlay = new Konva.Rect({
        x,
        y,
        width,
        height,
        fill: 'rgba(255, 255, 255, 0.3)',
        id: '__overlay',
      })
      this.context.layer.add(this.stupidOverlay)
      this.context.layer.draw()
    } else {
      this.stupidOverlay?.destroy()
      this.context.layer.draw()
    }
  }

  /**
   * Slightly modified version from the base method, which uses the storyboard's width and height
   * to calculate the component's aspect ratio, rather than the component's width and height.
   * @param shape The Konva.Shape to normalize
   * @param data The data to use for the normalization
   */
  private normalizeShape(
    shape: Konva.Shape,
    data: {
      width: number
      height: number
      scaleType: 'contain' | 'stretch' | 'contain-blur'
      storyboardData: StoryboardData
    }
  ) {
    const desiredAspectRatio = data.width / data.height

    if (data.scaleType === 'contain') {
      this.context.layer.destroyChildren()

      this.contain(data.width, data.height)
      return
    }

    if (data.scaleType === 'contain-blur') {
      this.context.layer.destroyChildren()

      this.containAndBlur(data.width, data.height, data.storyboardData)
      return
    }

    shape.height(shape.height())
    shape.width(shape.height() * desiredAspectRatio)

    shape.x((this.context.layer.width() - shape.width()) / 2)
    shape.y(this.y)
  }

  /**
   * Method to contain the image within the component's bounds.
   * @param width The width of the storyboard
   * @param height The height of the storyboard
   */
  private contain(width: number, height: number) {
    if (!this.shape) return

    const maxResolution = this.getMaxResolution(
      { width: this.context.component.width, height: this.context.component.height },
      { width, height }
    )

    this.shape.height(maxResolution.height)
    this.shape.width(maxResolution.width)

    this.shape.x((this.context.component.width - this.shape.width()) / 2)
    this.shape.y(this.y + (this.context.component.height - this.shape.height()) / 2)
  }

  private setLoading() {
    this.shape?.destroy()

    this.shape = new Konva.Rect({
      x: this.x,
      y: this.y,
      width: this.context.component.width,
      height: this.context.component.height,
      fill: '#363636',
      id: this.context.component.id,
    })

    this.context.layer.add(this.shape)
    this.context.layer.draw()
  }

  private setStoryboardImage(storyboard: StoryboardData) {
    if (!this.shape) return

    const { width, height, rows, columns } = storyboard

    const middle = {
      x: Math.floor(columns / 2) * width,
      y: Math.floor(rows / 2) * height,
    }

    const crop = {
      x: middle.x,
      y: middle.y,
      width,
      height,
    }

    const image = new Image()

    image.src = `data:image/png;base64,${storyboard.image}`

    image.onload = () => {
      this.shape?.destroy()

      this.shape = new Konva.Image({
        x: 0,
        y: 0,
        width: this.context.component.width,
        height: this.context.component.height,
        image,
        crop,
        stroke: 'red',
        strokeWidth: 12,
        strokeEnabled: false,
        id: this.context.component.id,
      })

      this.shape.on('click', () => {
        this.onSelected()
      })

      this.shape.on('mouseenter', () => {
        this.context.stage.container().style.cursor = 'pointer'
      })

      this.shape.on('mouseleave', () => {
        this.context.stage.container().style.cursor = 'default'
      })

      this.normalizeShape(this.shape, {
        width: width,
        height: height,
        scaleType: this.context.component.data['scaleType'] as 'contain' | 'stretch' | 'contain-blur',
        storyboardData: storyboard,
      })

      this.context.layer.add(this.shape)
      this.context.layer.draw()

      this.redrawOverlay()
    }

    // image.src = storyboard.image
  }

  private setNoMediaImage() {
    this.shape?.destroy()

    this.shape = new Konva.Rect({
      x: this.x,
      y: this.y,
      width: this.context.component.width,
      height: this.context.component.height,
      fill: '#222',
      id: this.context.component.id,
    })

    Konva.Image.fromURL('/assets/img/svg/no-video.svg', img => {
      const size = 200
      img.setAttrs({
        x: this.x + (this.context.component.width - size) / 2,
        y: this.y + (this.context.component.height - size) / 2,
        width: size,
        height: size,
      })

      this.context.layer.add(img)

      const text = new Konva.Text({
        x: this.x,
        y: img.y() + size + 20,
        width: this.context.component.width,
        text: 'no media selected',
        fontSize: 52,
        fontFamily: 'Inter',
        fill: '#888',
        align: 'center',
      })

      this.context.layer.add(text)

      this.stupidOverlay?.zIndex(this.context.layer.children.length - 1)

      this.context.layer.draw()
    })

    this.context.layer.add(this.shape)
    this.context.layer.draw()

    this.shape.on('click', () => {
      this.onSelected()
    })

    this.shape.on('mouseenter', () => {
      this.context.stage.container().style.cursor = 'pointer'
    })

    this.shape.on('mouseleave', () => {
      this.context.stage.container().style.cursor = 'default'
    })

    this.redrawOverlay()
  }

  /**
   * Contains the video, but also adds a blur effect to the background, so it isn't just black.
   * @param width The desired width
   * @param height The desired height
   */
  private containAndBlur(width: number, height: number, storyboardData: StoryboardData) {
    const image = new Image()
    image.src = `data:image/png;base64,${storyboardData.image}`

    const { width: w, height: h, rows, columns } = storyboardData

    const middle = {
      x: Math.floor(columns / 2) * w,
      y: Math.floor(rows / 2) * h,
    }

    const crop = {
      x: middle.x,
      y: middle.y,
      width: w,
      height: h,
    }

    image.onload = () => {
      const aspectRatio = width / height
      const heightDifference = this.context.component.height - height
      const newWidth = heightDifference * aspectRatio
      const bg = new Konva.Image({
        x: -newWidth / 2 + this.context.component.width / 2,
        y: this.y,
        width: newWidth,
        height: this.context.component.height,
        image,
        crop,
        blurRadius: 32,
        id: '__bg',
      })

      bg.cache()
      bg.filters([Konva.Filters.Blur])

      bg.on('click', () => {
        this.onSelected()
      })

      this.context.layer.add(bg)
      this.context.layer.draw()

      this.contain(width, height)

      this.shape?.zIndex(this.context.layer.children.length - 1)

      this.onComponentSelected(this.context.component)
    }
  }

  private getOverlayBounds(): { x: number; y: number; width: number; height: number } {
    return {
      x: this.x,
      y: this.y,
      width: this.context.component.width,
      height: this.context.component.height,
    }
  }
}
