<div class="w-full h-full flex justify-between gap-1">
  @for (i of stepArray(); track $index) {
  <div
    class="flex-1 h-1 transition-all duration-300"
    [ngClass]="{
      'bg-brand': $index <= currentStep() && !light(),
      'bg-brand-transparent': $index <= currentStep() && light(),
      'bg-light': $index > currentStep(),
      'rounded-l': $index === 0,
      'rounded-r': $index === steps() - 1
    }"></div>
  }
</div>
