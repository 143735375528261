import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/app/environments/environment'
import { PaginatedResponse } from '../../interfaces/paginated-response'
import { BaseOrganisation, MinimalOrganisation, Organisation } from '../../interfaces/organisation.interface'
import { OrganisationInvite } from '../../interfaces/organisation/organisation-invite'

@Injectable({
  providedIn: 'root',
})
export class OrganisationApiService {
  constructor(private http: HttpClient) {}

  public listOrganisations(filter?: Partial<Organisation>) {
    return this.http.get<PaginatedResponse<MinimalOrganisation>>(`${environment.baseApiUrl}/organisations/`, {
      params: filter as any,
    })
  }

  public retrieveOrganisation(uuid: string) {
    return this.http.get<Organisation>(`${environment.baseApiUrl}/organisations/${uuid}/`)
  }

  public createOrganisation(organisation: BaseOrganisation) {
    return this.http.post<Organisation>(`${environment.baseApiUrl}/organisations/`, organisation)
  }

  public makeOwner(organisationUuid: string, userUuid: string) {
    return this.http.post<Organisation>(`${environment.baseApiUrl}/organisations/${organisationUuid}/make-owner/`, {
      user: userUuid,
    })
  }

  public removeMember(organisationUuid: string, userUuid: string) {
    return this.http.post<Organisation>(`${environment.baseApiUrl}/organisations/${organisationUuid}/remove-user/`, {
      user: userUuid,
    })
  }

  public deleteOrganisation(organisationUuid: string) {
    return this.http.delete<null>(`${environment.baseApiUrl}/organisations/${organisationUuid}/`)
  }

  public partialUpdateOrganisation(organisationUuid: string, organisation: Partial<BaseOrganisation>) {
    return this.http.patch<Organisation>(`${environment.baseApiUrl}/organisations/${organisationUuid}/`, organisation)
  }

  public inviteMember(organisationUuid: string, email: string) {
    return this.http.post<null>(`${environment.baseApiUrl}/organisation-invites/`, {
      email,
      organisation: organisationUuid,
    })
  }

  public listInvites(organisationUuid: string) {
    return this.http.get<PaginatedResponse<OrganisationInvite>>(
      `${environment.baseApiUrl}/organisation-invites/?organisation=${organisationUuid}`
    )
  }

  public revokeInvite(inviteOrgUuid: string, inviteId: number) {
    return this.http.delete<null>(
      `${environment.baseApiUrl}/organisation-invites/${inviteId}/?organisation=${inviteOrgUuid}`
    )
  }

  public joinOrganisation(organisationCode: string) {
    return this.http.post<Organisation>(`${environment.baseApiUrl}/organisation-invites/accept/`, {
      inviteCode: organisationCode,
    })
  }

  public getProcessingLimits(organisationUuid: string) {
    return this.http.get<{ maxProcessingSeconds: number; usedProcessingSeconds: number }>(
      `${environment.baseApiUrl}/organisations/${organisationUuid}/processing-limits/`
    )
  }
}
