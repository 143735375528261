<div class="flex full-height">
  <app-sidebar *ngIf="showSidebar" class="top-0 h-full shrink-0 w-[18rem] sticky" />
  <div class="flex flex-col flex-1">
    <app-navbar class="h-[4rem]" />
    <div class="flex flex-col flex-1 overflow-auto p-8 w-screen xl:w-[calc(100vw-18rem)] relative">
      <ui-loading-bar [@fadeInOut] *ngIf="loading" class="w-full block absolute top-0 left-0 h-1" />
      <!-- <div [@routeAnimations]="getRouteAnimationData()"> -->
      <router-outlet></router-outlet>
      <!-- </div> -->
    </div>
  </div>
</div>
