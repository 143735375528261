import { ChangeContext, NgxSliderModule, Options } from '@angular-slider/ngx-slider'
import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'ui-double-slider',
  standalone: true,
  imports: [CommonModule, NgxSliderModule],
  templateUrl: './double-slider.component.html',
  styleUrl: './double-slider.component.scss',
})
export class DoubleSliderComponent {
  @Input()
  public min = 0

  @Input()
  public max = 100

  @Input()
  public step = 0

  @Input()
  public value: [number, number] = [0, 100]

  @Input()
  public maxRange?: number

  protected get options(): Options {
    return {
      floor: this.min,
      ceil: this.max,
      step: this.step,
      maxRange: this.maxRange,
      draggableRange: true,
      translate: (value: number) => {
        // convert to minutes/time string
        return this.numberToTimeStr(value)
      },
      // combineLabels: (minValue: string, maxValue: string) => {
      //   return `${minValue} - ${maxValue}`
      // },
    }
  }

  @Output()
  public valueChange: EventEmitter<[number, number]> = new EventEmitter()

  protected onUserChange(event: ChangeContext) {
    this.valueChange.emit([event.value, event.highValue ?? event.value])
  }

  private numberToTimeStr(value: number): string {
    const d = new Date(0, 0, 0, 0, 0, 0, 0)
    d.setSeconds(value)
    if (d.getHours() > 0) {
      return d.toTimeString().substring(0, 8)
    }

    return d.toTimeString().substring(3, 8)
  }
}
