import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PaginatedListComponent } from './paginated-list.component'
import { IconModule } from '../icon/icon.module'

@NgModule({
  declarations: [PaginatedListComponent],
  imports: [CommonModule, IconModule],
  exports: [PaginatedListComponent],
})
export class PaginatedListModule {}
