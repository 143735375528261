import { Component, input } from '@angular/core'
import { SidebarPanel } from '../sidebar.component'

@Component({
  selector: 'app-admin-panel',
  standalone: true,
  imports: [],
  templateUrl: './admin-panel.component.html',
  styleUrl: './admin-panel.component.scss',
})
export class AdminPanelComponent {
  public activePanel = input.required<SidebarPanel | null>()
}
