import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { VideoListPageComponent } from './pages/video-list-page/video-list-page.component'
import { UiModule } from '../ui/ui.module'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

@NgModule({
  declarations: [],
  imports: [CommonModule, UiModule, ReactiveFormsModule, RouterModule],
})
export class VideoModule {}
