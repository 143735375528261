import { Component, Input } from '@angular/core'
import { SidebarPanel } from '../sidebar.component'

@Component({
  selector: 'app-settings-panel',
  templateUrl: './settings-panel.component.html',
  styleUrls: ['./settings-panel.component.scss'],
})
export class SettingsPanelComponent {
  @Input()
  public activePanel: SidebarPanel | null = null
}
