import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'

@Component({
  selector: 'ui-toggle-slider',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './toggle-slider.component.html',
  styleUrl: './toggle-slider.component.scss',
})
export class ToggleSliderComponent implements OnInit {
  @Input()
  public toggled = false

  @Output()
  public toggledChange = new EventEmitter<boolean>()

  protected toggledControl = new FormControl(false, { nonNullable: true })

  ngOnInit(): void {
    this.toggledControl.setValue(this.toggled)

    this.toggledControl.valueChanges.subscribe(value => {
      this.toggled = value
      this.toggledChange.emit(value)
    })
  }
}
