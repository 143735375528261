import { CanActivateFn, Router } from '@angular/router'
import { AuthService } from '../services/auth/auth.service'
import { inject } from '@angular/core'

export const authorizedGuard: CanActivateFn = (route, state) => {
  const authService: AuthService = inject(AuthService)
  const router: Router = inject(Router)

  const currentUrl = state.url

  const { isEmailVerified } = authService.cachedAccountInformation ?? { isEmailVerified: false }
  if (!isEmailVerified && !currentUrl.startsWith('/auth/verify-email')) {
    router.navigate(['/auth/verify-email'], {
      queryParams: {
        ...authService.desiredRedirectUrlQueryParams,
        then: state.url,
      },
    })
  }

  if (!authService.cachedAccountInformation) {
    router.navigate(['/auth/login'], {
      queryParams: {
        ...authService.desiredRedirectUrlQueryParams,
        then: state.url,
      },
    })
  }

  return Boolean(authService.cachedAccountInformation)
}
