import { Component } from '@angular/core'
import { ToastService } from '../toast.service'

@Component({
  selector: 'ui-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
})
export class ToastContainerComponent {
  constructor(protected toastService: ToastService) {}
}
