import { Component, EventEmitter, Output, computed, signal } from '@angular/core'
import { SliderComponent } from 'src/app/ui/slider/slider.component'
import { OrganisationService } from '../../services/organisation/organisation.service'
import { Observable, map, shareReplay, switchMap } from 'rxjs'
import { IconModule } from 'src/app/ui/icon/icon.module'
import { CommonModule } from '@angular/common'

import { toObservable } from '@angular/core/rxjs-interop'
import { NgxTippyModule } from 'ngx-tippy-wrapper'
import { FormControl, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms'
import { ToggleSliderComponent } from 'src/app/ui/toggle-slider/toggle-slider.component'

export interface AiOptions {
  clipCount: number
  theme: string
  subtitles: boolean
}

@Component({
  selector: 'app-ai-creation-video-options-layout',
  standalone: true,
  imports: [SliderComponent, IconModule, CommonModule, NgxTippyModule, ReactiveFormsModule, ToggleSliderComponent],
  templateUrl: './ai-creation-video-options-layout.component.html',
  styleUrl: './ai-creation-video-options-layout.component.scss',
})
export class AiCreationVideoOptionsLayoutComponent {
  constructor(
    private organisationService: OrganisationService,
    private fb: NonNullableFormBuilder
  ) {}

  protected clipCount = signal(1)

  protected form = this.fb.group({
    theme: [''],
    subtitles: [false],
  })

  @Output()
  public validityChanged = new EventEmitter<boolean>()

  @Output()
  public aiOptions: Observable<AiOptions> = this.form.valueChanges.pipe(
    map(() => {
      const { theme, subtitles } = this.form.getRawValue()
      return {
        clipCount: this.clipCount(),
        theme,
        subtitles,
      }
    })
  )

  protected activeOrganisationProcessingData$ = this.organisationService.getActiveOrganisation().pipe(
    switchMap(organisation => {
      if (!organisation) return []
      return this.organisationService.getProcessingLimits(organisation.uuid)
    }),
    shareReplay(1) // prevents repeated API calls
  )

  protected minuteEstimate = computed(() => {
    switch (this.clipCount()) {
      case 1:
        return 5
      case 2:
        return 10
      case 3:
        return 15
    }

    return 0
  })

  protected clipCountInvalid = toObservable(this.clipCount).pipe(
    switchMap(clipCount => this.activeOrganisationProcessingData$),
    map(processingData => {
      if (!processingData) return { invalid: true }
      const invalid =
        this.minuteEstimate() * 60 > processingData.maxProcessingSeconds - processingData.usedProcessingSeconds

      this.validityChanged.emit(!invalid)

      return {
        invalid,
      }
    })
  )

  protected onSubtitleChange(subtitles: boolean) {
    this.form.controls.subtitles.setValue(subtitles)
  }
}
