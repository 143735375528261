import { NgModule } from '@angular/core'
import { iconProvider } from './icon.provider'
import { NgIconsModule } from '@ng-icons/core'

@NgModule({
  declarations: [],
  imports: [NgIconsModule],
  exports: [NgIconsModule],
  providers: [iconProvider],
})
export class IconModule {}
