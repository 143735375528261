<div class="bg-white h-full border-b flex items-center justify-between gap-6">
  <div class="pl-2">
    <button
      class="xl:hidden h-10 w-10 flex items-center justify-center transition-colors hover:bg-neutral-100 focus:bg-neutral-100 rounded-full focus:outline-none focus-visible:ring-brand focus-visible:ring-2 focus-visible:transition-none active:outline-none"
      (click)="openSidebarOverlay()">
      <ng-icon name="heroBars3" class="text-2xl text-neutral-700" />
    </button>
  </div>
  <div *ngIf="user$ | async as user">
    <button
      class="flex items-center justify-start gap-2 rounded-full hover:bg-neutral-100 mr-4 p-2"
      (click)="accountMenuOpened = true"
      cdkOverlayOrigin
      #accountMenuOrigin="cdkOverlayOrigin">
      <div class="w-6 h-fit aspect-square bg-light rounded-full"></div>
      <div class="w-32 text-left">
        <div class="text-xs font-light truncate">{{ user.email }}</div>
      </div>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayHasBackdrop]="true"
      (backdropClick)="accountMenuOpened = false"
      [cdkConnectedOverlayOrigin]="accountMenuOrigin"
      [cdkConnectedOverlayOpen]="accountMenuOpened">
      <ui-card class="w-64 overflow-hidden">
        <button
          class="w-full p-4 text-left text-xs hover:bg-neutral-200 flex items-center gap-2"
          (click)="accountMenuOpened = false; logout()">
          <ng-icon name="heroArrowRightOnRectangle" class="text-lg" />
          Logout
        </button>
      </ui-card>
    </ng-template>
  </div>
</div>
