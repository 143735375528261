import { ChangeContext, NgxSliderModule, Options } from '@angular-slider/ngx-slider'
import { Component, EventEmitter, Input, Output, input } from '@angular/core'

@Component({
  selector: 'ui-slider',
  standalone: true,
  imports: [NgxSliderModule],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss',
})
export class SliderComponent {
  @Input()
  public min = 0

  @Input()
  public max = 100

  @Input()
  public step = 0

  @Input()
  public value = 0

  @Output()
  public valueChange = new EventEmitter<number>()

  protected get options(): Options {
    return {
      floor: this.min,
      ceil: this.max,
      step: this.step,
      showSelectionBar: true,
      showTicks: true,
      showTicksValues: true,
    }
  }

  protected onUserChange(event: ChangeContext) {
    this.valueChange.emit(event.value)
  }
}
