import { Component, Input } from '@angular/core'
import { SidebarPanel } from '../sidebar.component'

@Component({
  selector: 'app-video-panel',
  templateUrl: './video-panel.component.html',
  styleUrls: ['./video-panel.component.scss'],
})
export class VideoPanelComponent {
  @Input()
  public activePanel: SidebarPanel | null = null
}
