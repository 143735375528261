<div
  class="w-full h-full bg-gradient-to-r from-brand to-violet-500 text-darkest rounded-lg flex flex-col justify-center p-6 px-8 gap-2 relative isolate">
  <div class="inset-0 absolute grid-bg"></div>
  <div class="flex gap-2 w-full items-center text-lighter z-10">
    <ng-icon name="heroSparklesSolid" class="text-xl" />
    <span class="font-light"> New </span>
  </div>
  <h1 class="text-4xl font-extrabold text-left text-lightest w-full z-10">Create video with AI.</h1>
  <h5 class="text-sm font-medium text-left text-lightest w-full z-10">
    Turn long-form content into short, viral videos in minutes.
  </h5>
  <button class="button-secondary w-fit text-xs mt-2 z-10" (click)="openCreationOverlay()">Get Started</button>
</div>
