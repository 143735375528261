<ui-card [@enterFromBottom] class="max-w-sm w-screen block p-4 h-fit max-full-height overflow-y-auto">
  <h1 class="text-xl font-semibold mb-4">Create new organisation</h1>
  <form class="flex flex-col gap-4 mt-4" [formGroup]="form">
    <div class="ml-auto text-right">
      <label class="text-xs text-mid font-light">Organisation Name</label>
      <input
        formControlName="name"
        type="text"
        class="input px-1 py-1 text-sm w-72 block text-right text-dark"
        placeholder="New Organisation" />
    </div>
    <div class="mt-4">
      <div class="flex gap-2 mb-1 font-medium text-darkest">Members</div>
      <div class="flex flex-col">
        <div class="ml-auto text-right">
          <label class="text-xs text-mid font-light">Invite members</label>
          <input
            [formControl]="membersEmail"
            type="email"
            class="input px-1 py-1 text-sm w-72 block text-right text-dark"
            (keyup.enter)="addMember()"
            [ngClass]="{ 'cursor-not-allowed': membersEmail.disabled }"
            placeholder="john.doe@example.com" />
        </div>
      </div>
      <div class="mt-6 text-right">
        <div class="text-darker font-light text-sm">
          Current Members <span class="text-mid text-xs">({{ membersEmails.length + 1 }}/{{ maxMembers }})</span>
        </div>
        <div class="mt-2 text-sm flex flex-col gap-2 items-end">
          <div class="bg-lighter px-2 py-1 rounded-full w-fit cursor-not-allowed select-none">
            <span class="opacity-50">
              {{ currentUser?.email }}
            </span>
          </div>
          <div
            *ngFor="let email of membersEmails; let i = index"
            [@fadeInOutFromRight]
            class="bg-lighter px-2 py-1 rounded-full w-fit flex items-center">
            <span class="max-w-[14rem] truncate">
              {{ email }}
            </span>
            <ng-icon name="heroXMark" class="w-4 h-4 ml-1 cursor-pointer" (click)="removeMember(i)"></ng-icon>
          </div>
        </div>
      </div>
    </div>
    <button
      type="submit"
      class="button-primary text-xs ml-auto mt-4 relative"
      [ngClass]="{ disabled: !form.valid || loading }"
      [disabled]="!form.valid || loading"
      (click)="createOrganisation()">
      <span [ngClass]="{ hidden: !loading }" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <svg
          class="animate-spin h-4 w-4 text-dark opacity-50"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </span>
      <span [ngClass]="{ 'opacity-0': loading }"> Create organisation </span>
    </button>
  </form>
</ui-card>
