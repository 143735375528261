import { provideIcons } from '@ng-icons/core'
import {
  heroArrowTopRightOnSquare,
  heroChevronDown,
  heroPlus,
  heroXMark,
  heroCog6Tooth,
  heroTrash,
  heroArrowTrendingUp,
  heroCheckCircle,
  heroChevronLeft,
  heroChevronRight,
  heroComputerDesktop,
  heroVideoCamera,
  heroRectangleGroup,
  heroUsers,
  heroExclamationCircle,
  heroCalendar,
  heroBars3,
  heroArrowDownOnSquare,
  heroArrowDown,
  heroRocketLaunch,
  heroSparkles,
  heroArrowRightOnRectangle,
  heroArrowRight,
  heroExclamationTriangle,
  heroCheckBadge,
  heroInformationCircle,
  heroMagnifyingGlass,
  heroCloudArrowUp,
} from '@ng-icons/heroicons/outline'
import {
  heroUsersSolid,
  heroCog6ToothSolid,
  heroRocketLaunchSolid,
  heroArrowDownOnSquareSolid,
  heroArrowDownTraySolid,
  heroSparklesSolid,
  heroExclamationCircleSolid,
  heroCheckCircleSolid,
  heroCheckBadgeSolid,
  heroBeakerSolid,
  heroWrenchSolid,
} from '@ng-icons/heroicons/solid'

export const iconProvider = provideIcons({
  heroArrowRight,
  heroRocketLaunchSolid,
  heroArrowDownTraySolid,
  heroArrowTopRightOnSquare,
  heroChevronDown,
  heroPlus,
  heroXMark,
  heroCog6Tooth,
  heroTrash,
  heroArrowTrendingUp,
  heroCheckCircle,
  heroUsersSolid,
  heroCog6ToothSolid,
  heroChevronLeft,
  heroChevronRight,
  heroComputerDesktop,
  heroVideoCamera,
  heroRectangleGroup,
  heroUsers,
  heroExclamationCircle,
  heroExclamationCircleSolid,
  heroExclamationTriangle,
  heroCalendar,
  heroBars3,
  heroSparklesSolid,
  heroArrowRightOnRectangle,
  heroCheckBadgeSolid,
  heroBeakerSolid,
  heroWrenchSolid,
  heroInformationCircle,
  heroMagnifyingGlass,
  heroCloudArrowUp,
})
