<div class="w-full border border-gray-300 rounded-2xl relative">
  <ng-container *ngIf="activeOrganisation$ | async as activeOrg; else noActiveOrg">
    <div class="border-b p-4">
      <div class="font-medium pr-6 truncate">{{ activeOrg.name }}</div>
      <div class="mt-1 text-xs font-medium text-gray-500">
        {{ activeOrg.users.length }} <span class="font-light">member{{ activeOrg.users.length === 1 ? '' : 's' }}</span>
      </div>
    </div>
    <button
      class="absolute top-3 right-3 rounded-full hover:bg-lighter focus:bg-lighter transition-colors focus:outline-none w-5 h-5 flex items-center justify-center default-ring focus:bg-lighter focus-visible:bg-lighter focus-visible:transition-none"
      #selectorButton
      (click)="organisationsSelectorOpened = true; selectorButton.blur()"
      cdkOverlayOrigin
      #overlayOrigin="cdkOverlayOrigin">
      <ng-icon name="heroChevronDown"></ng-icon>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOffsetY]="0"
      [cdkConnectedOverlayPositions]="[
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        }
      ]"
      [cdkConnectedOverlayBackdropClass]="['cdk-overlay-transparent-backdrop']"
      (backdropClick)="organisationsSelectorOpened = false"
      [cdkConnectedOverlayOrigin]="overlayOrigin"
      [cdkConnectedOverlayOpen]="organisationsSelectorOpened">
      <div
        *ngIf="organisations$ | async as organisations"
        [@fadeInOutFromTop]
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
        class="flex flex-col bg-white shadow-md rounded-md text-sm overflow-visible"
        [ngStyle]="{ 'min-width.px': selectorButton.clientWidth }">
        <ng-container *ngFor="let organisation of organisations">
          <button
            *ngIf="organisation.uuid !== activeOrg.uuid"
            class="focus:outline-none text-left py-3 px-3 hover:bg-lighter transition-colors font-light default-ring focus-visible:bg-lighter focus-visible:transition-none first:rounded-t-md"
            (click)="setActiveOrganisation(organisation)">
            {{ organisation.name }}
          </button>
        </ng-container>
        <button
          *ngIf="canCreateNewOrganisation(organisations)"
          (click)="openCreateOrganisationDialog()"
          class="py-3 px-3 text-xs hover:bg-lighter transition-colors focus:bg-lighter focus:outline-none flex items-center gap-2 font-light border-gray-200 default-ring focus-visible:bg-lighter focus-visible:transition-none"
          [ngClass]="{ 'border-t': organisations.length > 1 }">
          <ng-icon name="heroPlus" class=""></ng-icon>
          <span class="">Create new organisation</span>
        </button>
        <a
          [routerLink]="['/settings/organisations']"
          class="py-3 px-3 text-xs hover:bg-lighter transition-colors focus:bg-lighter focus:outline-none flex items-center gap-2 font-light default-ring rounded-b-md focus-visible:bg-lighter focus-visible:transition-none"
          [ngClass]="{ 'border-t border-gray-200': !canCreateNewOrganisation(organisations) }"
          (click)="organisationsSelectorOpened = false; navigated.emit()">
          <ng-icon name="heroCog6Tooth" class=""></ng-icon>
          <span class="">Organisation settings</span>
        </a>
      </div>
    </ng-template>
    <a
      [routerLink]="['/settings/organisations']"
      [fragment]="canInviteMembers(activeOrg) ? 'invite' : undefined"
      class="p-4 text-dark text-lg flex items-center w-full rounded-b-2xl hover:bg-lighter transition-colors focus:outline-none hover:text-mid focus:text-mid default-ring focus:bg-lighter focus:transition-none focus-visible:bg-lighter"
      #cta
      (click)="cta.blur(); navigated.emit()">
      <ng-container *ngIf="canInviteMembers(activeOrg); else organisationSettings">
        <ng-icon name="heroUsersSolid" class="h-0" />
        <span class="ml-2 text-sm">Invite members</span>
      </ng-container>
      <ng-template #organisationSettings>
        <ng-icon name="heroCog6ToothSolid" class="h-0" />
        <span class="ml-2 text-sm">Organisation settings</span>
      </ng-template>
    </a>
  </ng-container>
  <ng-template #noActiveOrg>
    <div class="border-b p-4">
      <div class="w-48 h-6 bg-gray-200 rounded-md animate-pulse"></div>
      <div class="mt-1 bg-gray-100 h-4 w-16 rounded-md animate-pulse"></div>
    </div>
    <div
      class="absolute top-3 right-3 rounded-full hover:bg-lighter focus:bg-lighter transition-colors focus:outline-none w-5 h-5 flex items-center justify-center">
      <ng-icon name="heroChevronDown"></ng-icon>
    </div>
    <a
      [routerLink]="['/settings/organisations']"
      class="p-4 text-dark text-lg flex items-center w-full rounded-b-2xl hover:bg-lighter focus:bg-lighter transition-colors focus:outline-none hover:text-mid focus:text-mid"
      #cta
      (click)="cta.blur()">
      <div class="w-5 h-5 bg-gray-200 rounded-full animate-pulse"></div>
      <span class="ml-1.5 bg-gray-100 h-4 w-32 rounded-md animate-pulse"></span>
    </a>
  </ng-template>
</div>
