import { Component, Input, Output } from '@angular/core'
import { Render } from '../../interfaces/render'
import { VideoService } from '../../services/video.service'

@Component({
  selector: 'app-render-preview-dialog',
  templateUrl: './render-preview-dialog.component.html',
  styleUrls: ['./render-preview-dialog.component.scss'],
})
export class RenderPreviewDialogComponent {
  constructor(private videoService: VideoService) {}

  @Input({ required: true })
  public render!: Render

  @Output()
  public closeDialog = () => {}

  protected showVideo = false

  protected get aspectRatio(): number {
    return this.render.video.metadata.width / this.render.video.metadata.height
  }

  protected downloadVideo(): void {
    const url = this.render.output
    if (!url) return

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = 'render.mp4'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
  }
}
