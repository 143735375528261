import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SettingsPageComponent } from './pages/settings-page/settings-page.component'
import { UiModule } from '../ui/ui.module'
import { RouterModule } from '@angular/router'
import { OrganisationSettingsPageComponent } from './pages/organisation-settings-page/organisation-settings-page.component'
import { OrganisationMembersTableComponent } from './components/organisation-members-table/organisation-members-table.component'
import { DeleteOrganisationModalComponent } from './components/delete-organisation-modal/delete-organisation-modal.component'
import { ReactiveFormsModule } from '@angular/forms'
import { OrganisationSettingsInvitesSectionComponent } from './pages/organisation-settings-page/organisation-settings-invites-section/organisation-settings-invites-section.component'
import { OrganisationInviteCardComponent } from './components/organisation/organisation-invite-card/organisation-invite-card.component'
import { OrganisationInvitesTableComponent } from './components/organisation/organisation-invites-table/organisation-invites-table.component'

@NgModule({
  declarations: [
    SettingsPageComponent,
    OrganisationSettingsPageComponent,
    OrganisationMembersTableComponent,
    DeleteOrganisationModalComponent,
    OrganisationSettingsInvitesSectionComponent,
    OrganisationInviteCardComponent,
    OrganisationInvitesTableComponent,
  ],
  imports: [CommonModule, UiModule, RouterModule, ReactiveFormsModule],
})
export class SettingsModule {}
