<div class="w-fit bg-lightest rounded-md shadow-md overflow-hidden">
  <div class="flex items-center w-full h-8 px-2 gap-2">
    <svg
      *ngIf="toast?.type === 'loading'"
      class="animate-spin h-4 w-4 text-mid"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24">
      <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    <svg
      *ngIf="toast?.type === 'success'"
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4 text-emerald-700"
      viewBox="0 0 24 24">
      <path fill="currentColor" d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
    </svg>
    <span
      class="text-xs transition-all"
      [ngClass]="{
        'text-dark': toast?.type === 'loading' || toast?.type === 'info',
        'text-emerald-900': toast?.type === 'success',
        'text-red-400': toast?.type === 'error'
      }">
      {{ toast?.message }}
    </span>
  </div>
  <div
    class="h-[2px] w-full transition-all"
    [ngClass]="{
      'bg-mid': toast?.type === 'loading' || toast?.type === 'info',
      'bg-emerald-700': toast?.type === 'success',
      'bg-red-400': toast?.type === 'error'
    }"></div>
</div>
