import { Component, Input } from '@angular/core'
import { SidebarPanel } from '../sidebar.component'

@Component({
  selector: 'app-statistics-panel',
  templateUrl: './statistics-panel.component.html',
  styleUrls: ['./statistics-panel.component.scss'],
})
export class StatisticsPanelComponent {
  @Input()
  public activePanel: SidebarPanel | null = null
}
