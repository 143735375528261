import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MinimalOrganisation } from '../../interfaces/organisation.interface'
import { animations } from 'src/app/ui/animations'
import { FormControl, NonNullableFormBuilder, Validators } from '@angular/forms'
import { AuthService } from 'src/app/auth/services/auth/auth.service'
import { OrganisationService } from '../../services/organisation/organisation.service'
import { catchError } from 'rxjs'
import { ToastService } from 'src/app/ui/toast/toast.service'

@Component({
  selector: 'app-create-organisation-overlay',
  templateUrl: './create-organisation-overlay.component.html',
  styleUrls: ['./create-organisation-overlay.component.scss'],
  animations: [animations.enterFromBottom, animations.fadeInOutFromRight],
})
export class CreateOrganisationOverlayComponent {
  constructor(
    private fb: NonNullableFormBuilder,
    private authService: AuthService,
    private organisationService: OrganisationService,
    private toast: ToastService
  ) {}
  @Output()
  public closeOverlay = new EventEmitter<MinimalOrganisation | null>()

  protected form = this.fb.group({
    name: ['', [Validators.required]],
  })

  protected membersEmail = new FormControl('', [Validators.email])
  protected currentUser = this.authService.cachedAccountInformation
  protected membersEmails: string[] = []
  protected loading = false
  protected maxMembers = this.organisationService.getMaxMembers(null)

  protected addMember() {
    if (!this.membersEmail || !this.membersEmail.valid || this.membersEmails.length >= this.maxMembers - 1) return

    const email = this.membersEmail.value?.toLowerCase()

    if (!email || this.membersEmails.includes(email) || email === this.currentUser?.email.toLowerCase()) {
      return
    }

    this.membersEmails.push(email)
    this.membersEmail.reset()

    if (this.membersEmails.length >= this.maxMembers - 1) {
      this.membersEmail.disable()
    }
  }

  protected removeMember(index: number) {
    this.membersEmails.splice(index, 1)
    this.membersEmail.enable()
  }

  protected createOrganisation() {
    if (!this.form.valid) return

    this.loading = true

    const name = this.form.value.name as string

    this.organisationService
      .createOrganisation({
        name,
        users: this.membersEmails,
      })
      .pipe(
        catchError(() => {
          this.loading = false
          this.toast.error('Failed to create organisation')
          this.closeOverlay.emit(null)
          return []
        })
      )
      .subscribe(organisation => {
        this.loading = false
        this.toast.success(`Created organisation ${organisation.name}`)
        this.closeOverlay.emit(organisation)
      })
  }
}
