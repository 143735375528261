import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CardModule } from './card/card.module'
import { TemplateCardModule } from './template-card/template-card.module'
import { ToastModule } from './toast/toast.module'
import { PaginatedListModule } from './paginated-list/paginated-list.module'
import { IconModule } from './icon/icon.module'
import { VideoPreviewModule } from './video-preview/video-preview.module'

const modules = [CardModule, TemplateCardModule, ToastModule, PaginatedListModule, IconModule, VideoPreviewModule]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...modules],
  exports: modules,
})
export class UiModule {}
