import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { catchError, from, switchMap, tap } from 'rxjs'
import YoutubePlayer from 'youtube-player'
import { YouTubePlayer } from 'youtube-player/dist/types'
import { VideoService } from '../../services/video.service'
import { IconModule } from 'src/app/ui/icon/icon.module'
import { NgxTippyModule } from 'ngx-tippy-wrapper'

@Component({
  selector: 'app-ai-creation-provide-video-layout',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, IconModule, NgxTippyModule],
  templateUrl: './ai-creation-provide-video-layout.component.html',
  styleUrl: './ai-creation-provide-video-layout.component.scss',
})
export class AiCreationProvideVideoLayoutComponent implements AfterViewInit, OnInit {
  constructor(private videoService: VideoService) {}

  @ViewChild('youtubePlayer')
  protected youtubePlayerElement?: ElementRef<HTMLDivElement>

  private player?: YouTubePlayer

  private YOUTUBE_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/

  protected urlControl = new FormControl('', { nonNullable: true })

  protected validityState: 'valid' | 'invalid' | 'pending' | 'waiting' = 'waiting'

  @Output()
  public currentVideoUrl = new EventEmitter<string | null>()

  ngOnInit(): void {
    this.urlControl.valueChanges.subscribe(url => {
      if (this.YOUTUBE_REGEX.test(url)) {
        this.attemptInitializePlayer(url)
        return
      }

      this.player?.destroy()
      this.player = undefined
      this.currentVideoUrl.emit(null)
    })
  }

  ngAfterViewInit(): void {
    const url = this.urlControl.getRawValue()
    this.attemptInitializePlayer(url)
  }

  private attemptInitializePlayer(url: string): void {
    if (!this.youtubePlayerElement?.nativeElement || !this.YOUTUBE_REGEX.test(url)) {
      return
    }

    if (!this.player) {
      this.player = YoutubePlayer(this.youtubePlayerElement.nativeElement, {
        playerVars: {
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          enablejsapi: 1,
          fs: 0,
          loop: 1,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
        },
        height: '180',
      })
    }

    from(this.player.loadVideoById(this.getIdFromYouTubeUrl(url)!))
      .pipe(
        tap(() => {
          this.validityState = 'pending'
          this.currentVideoUrl.emit(null)
        }),
        switchMap(() => this.videoService.getVideoUrlSuitableForAi(url)),
        catchError(() => [{ success: false }])
      )
      .subscribe(({ success }) => {
        this.validityState = success ? 'valid' : 'invalid'
        this.currentVideoUrl.emit(success ? url : null)
      })
  }

  private getIdFromYouTubeUrl(url: string): string | null {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/)
    if (!match) {
      return null
    }

    // don't include ?
    const queryIndex = match[1].indexOf('?')
    if (queryIndex !== -1) {
      return match[1].substring(0, queryIndex)
    }

    return match[1]
  }
}
