import { Component, Input } from '@angular/core'
import { Toast } from './toast'

@Component({
  selector: 'ui-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @Input() toast?: Toast
}
