import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'

import * as Sentry from '@sentry/angular'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { MainModule } from './main/main.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router, RouterModule } from '@angular/router'
import { AuthInterceptor } from './auth/interceptors/auth.interceptor'
import { ToastModule } from './ui/toast/toast.module'
import { httpInterceptorProviders } from './main/interceptors'
import { VideoModule } from './video/video.module'
import { AutomationModule } from './automation/automation.module'
import { SettingsModule } from './settings/settings.module'
import { StatisticsModule } from './statistics/statistics.module'
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MainModule,
    VideoModule,
    SettingsModule,
    AutomationModule,
    StatisticsModule,
    BrowserAnimationsModule,
    RouterModule,
    ToastModule,
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
