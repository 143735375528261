import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TemplateCardComponent } from './template-card.component'
import { CardModule } from '../card/card.module'
import { OverlayModule } from '@angular/cdk/overlay'
import { RenderErrorModalComponent } from './render-error-modal/render-error-modal.component'
import { VideoPreviewModule } from '../video-preview/video-preview.module'

@NgModule({
  declarations: [TemplateCardComponent, RenderErrorModalComponent],
  imports: [CommonModule, CardModule, OverlayModule, VideoPreviewModule],
  exports: [TemplateCardComponent],
})
export class TemplateCardModule {}
