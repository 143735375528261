<p class="font-semibold text-dark mb-2">Number of clips to generate</p>
<p
  *ngIf="clipCountInvalid | async as invalid"
  class="text-sm text-dark mb-4 flex items-center gap-1"
  [ngClass]="{ 'line-through': invalid.invalid }">
  Estimated processing time:
  <ng-container>
    @switch (clipCount()) { @case(1) {
    <span class="text-brand">5 minutes</span>
    } @case(2) {
    <span class="text-yellow-700">10 minutes</span>
    } @case(3) {
    <span class="text-red-800">15 minutes</span>
    } }
  </ng-container>
  <ng-icon
    *ngIf="invalid.invalid"
    name="heroExclamationTriangle"
    class="w-4 h-4 text-red-800 text-xl ml-1"
    [ngxTippy]="'You do not have enough processing minutes left to generate this many clips.'" />
</p>
<ui-slider [max]="3" [min]="1" (valueChange)="clipCount.set($event)" />
<p class="font-semibold text-dark mb-2 mt-4">Clip theme</p>
<textarea
  class="input text-sm px-1 py-0.5 w-full"
  [formControl]="form.controls.theme"
  placeholder="The clips should be comedic, motivational, etc. (max 100 characters)"
  rows="3"
  ngxTippy="Enter the theme of the clips you want to generate."
  [tippyProps]="{ placement: 'right', arrow: true, trigger: 'focus' }"
  maxlength="100"
  style="resize: none"></textarea>

<p class="font-semibold text-dark mb-0 mt-4">Subtitles</p>
<div class="flex gap-2 items-center">
  <ui-toggle-slider (toggledChange)="onSubtitleChange($event)" [toggled]="form.controls.subtitles.value" class="mt-2" />
  <p class="text-sm text-dark mt-2 cursor-default" (click)="onSubtitleChange(!form.controls.subtitles.value)">
    Subtitles {{ form.controls.subtitles.value ? 'enabled' : 'disabled' }}
  </p>
</div>
