import { Injectable } from '@angular/core'
import { Toast } from './toast'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  public toasts: Toast[] = []

  public loading(message: string) {
    return this.addToast({ message, type: 'loading', dismissible: false, duration: 0 })
  }

  public error(message: string, duration: number = 5000) {
    return this.addToast({ message, type: 'error', dismissible: true, duration })
  }

  public success(message: string, duration: number = 5000) {
    return this.addToast({ message, type: 'success', dismissible: true, duration })
  }

  public info(message: string, duration: number = 5000) {
    return this.addToast({ message, type: 'info', dismissible: true, duration })
  }

  private addToast(toast: Toast) {
    this.toasts.push(toast)
    if (toast.duration > 0) {
      setTimeout(() => (this.toasts = this.toasts.filter(t => t !== toast)), toast.duration)
    }

    return toast
  }
}
