import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/auth/services/auth/auth.service'
import { OrganisationService } from '../../services/organisation/organisation.service'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { SidebarComponent } from '../sidebar/sidebar.component'
import { FocusTrapFactory, FocusTrap } from '@angular/cdk/a11y'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private organisationService: OrganisationService,
    private overlay: Overlay,
    private focusTrapFactory: FocusTrapFactory
  ) {}

  protected user$ = this.authService.retrievePersistentUser()
  private overlayRef: OverlayRef | null = null
  private focusTrap: FocusTrap | null = null

  protected openSidebarOverlay() {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerVertically().left(),
      backdropClass: 'blurred-bg',
    })

    const portal = new ComponentPortal(SidebarComponent)
    const componentRef = this.overlayRef.attach(portal)

    this.focusTrap = this.focusTrapFactory.create(componentRef.location.nativeElement)
    this.focusTrap.focusInitialElementWhenReady()

    componentRef.location.nativeElement.style.height = '100vh'
    componentRef.location.nativeElement.style.width = '18rem'
    componentRef.location.nativeElement.style.maxWidth = '100vw'
    componentRef.instance.animationEnabled = true
    componentRef.instance.showCloseButton = true

    this.overlayRef.backdropClick().subscribe(() => {
      componentRef.instance.closeSidebar()
    })

    this.overlayRef.detachments().subscribe(() => {
      this.overlayRef = null
      this.focusTrap?.destroy()
    })

    componentRef.instance.navigate.subscribe(() => {
      componentRef.instance.closeSidebar()
    })

    componentRef.instance.sidebarClosed.subscribe(() => {
      this.overlayRef?.detach()
    })

    this.overlayRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        // this.overlayRef?.detach()
        componentRef.instance.sidebarShouldBeOpen = false
      }
    })
  }

  ngOnInit(): void {
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler.bind(this))
  }

  private XL = 1280

  private resizeHandler() {
    const width = window.innerWidth
    const shouldHideSidebar = width >= this.XL

    if (shouldHideSidebar) {
      this.overlayRef?.detach()
    }
  }

  protected accountMenuOpened = false

  protected logout() {
    this.authService.logout()
  }
}
