import { Component, Input } from '@angular/core'
import { SidebarPanel } from '../sidebar.component'

@Component({
  selector: 'app-dashboard-panel',
  templateUrl: './dashboard-panel.component.html',
  styleUrls: ['./dashboard-panel.component.scss'],
})
export class DashboardPanelComponent {
  @Input()
  public activePanel: SidebarPanel | null = null
}
