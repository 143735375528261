<div class="flex items-center gap-4">
  <div class="w-9 h-9 rounded-full items-center justify-center flex bg-[#DFE4EC]">
    <!-- attribution to https://www.svgrepo.com/ -->
    <div
      class="w-5 h-5 transition-colors mr-0.5"
      [ngClass]="{
        'text-brand': activePanel === 'automation',
        'text-[#4D6080]': activePanel !== 'automation'
      }">
      <svg width="22" height="20" viewBox="0 0 22 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.0427 12.8723L11.967 12.634C11.9562 11.9099 11.8011 11.2108 11.5065 10.5557L12.3385 9.88198C12.5365 9.72162 12.5669 9.43113 12.4068 9.23292L11.3636 7.94449C11.2866 7.8492 11.1749 7.78875 11.0531 7.77583C10.9319 7.76267 10.8094 7.79936 10.7144 7.8762L9.90425 8.53287C9.33064 8.09286 8.68666 7.7809 7.98985 7.60554V6.57692C7.98985 6.32219 7.78311 6.11545 7.52838 6.11545H5.87102C5.61629 6.11545 5.40955 6.32219 5.40955 6.57692V7.60623C4.69889 7.78552 4.04315 8.10716 3.46055 8.56241L2.64259 7.91173C2.54684 7.83559 2.4257 7.80121 2.30295 7.81436C2.18136 7.8282 2.07014 7.88981 1.994 7.9858L0.962161 9.28322C0.803416 9.48258 0.836641 9.77308 1.036 9.93159L1.87633 10.5998C1.58791 11.2569 1.43909 11.9563 1.43424 12.6787L0.357638 12.9275C0.109369 12.9852 -0.0454535 13.2325 0.0117685 13.4808L0.384634 15.0962C0.442087 15.3447 0.690356 15.4993 0.938164 15.442L2.04869 15.1857C2.3784 15.8045 2.82026 16.3435 3.36179 16.7877L2.8611 17.8375C2.75127 18.0676 2.84864 18.3428 3.07868 18.4527L4.57476 19.1668C4.63729 19.1966 4.70535 19.2116 4.77342 19.2116C4.82557 19.2116 4.87748 19.2028 4.92755 19.1853C5.04292 19.1442 5.13729 19.0595 5.19013 18.949L5.69728 17.8851C6.37171 18.015 7.07384 18.0129 7.74596 17.8777L8.26188 18.9354C8.37356 19.1647 8.65067 19.2591 8.8791 19.1481L10.3687 18.4218C10.5976 18.3101 10.6929 18.0337 10.5812 17.8048L10.0722 16.7609C10.611 16.3119 11.0485 15.7685 11.3733 15.146L12.4836 15.3922C12.7319 15.4469 12.9788 15.2904 13.0339 15.0412L13.3932 13.4229C13.4195 13.3033 13.3978 13.1781 13.3316 13.0749C13.266 12.9715 13.1622 12.8986 13.0427 12.8723ZM8.73466 12.7138C8.73466 13.835 7.82257 14.7471 6.7012 14.7471C5.5803 14.7471 4.66821 13.835 4.66821 12.7138C4.66821 11.5927 5.58007 10.6806 6.7012 10.6806C7.82257 10.6808 8.73466 11.5927 8.73466 12.7138Z"
          fill="currentColor"
          stroke="currentColor" />
        <path
          d="M14.2923 8.92142C14.4645 8.92142 14.6299 8.82452 14.7093 8.65862L14.9525 8.14915C15.3738 8.2306 15.8143 8.22875 16.2333 8.14431L16.4802 8.65077C16.5916 8.87966 16.8682 8.97496 17.0974 8.86328L17.7183 8.56032C17.8283 8.50656 17.9126 8.41127 17.9525 8.29567C17.9922 8.18007 17.9845 8.05316 17.9305 7.9431L17.6876 7.44587C18.024 7.16575 18.2981 6.82611 18.5014 6.43594L19.027 6.55223C19.2748 6.60714 19.5221 6.45024 19.5775 6.20151L19.7266 5.52707C19.7815 5.27833 19.6248 5.03191 19.3756 4.97676L18.8717 4.86508C18.8653 4.41238 18.7681 3.97513 18.5838 3.56558L18.9698 3.25293C19.0648 3.17586 19.1255 3.06419 19.138 2.94259C19.1509 2.82099 19.1144 2.69893 19.0374 2.60387L18.6027 2.06695C18.4419 1.86874 18.1511 1.83852 17.9536 1.99888L17.5812 2.30045C17.2227 2.02518 16.82 1.83021 16.3842 1.72061V1.24991C16.3842 0.995177 16.1774 0.788437 15.9227 0.788437H15.2319C14.9772 0.788437 14.7704 0.995177 14.7704 1.24991V1.72061C14.3258 1.83275 13.916 2.03395 13.5521 2.31868L13.1751 2.01918C12.9762 1.86067 12.686 1.89389 12.5268 2.09302L12.0974 2.6341C12.0212 2.73008 11.9864 2.85214 12 2.97374C12.0141 3.09534 12.0754 3.20655 12.1714 3.2827L12.5611 3.59303C12.3812 4.00351 12.288 4.44122 12.285 4.89346L11.7801 5.00999C11.6606 5.03744 11.5575 5.11128 11.4926 5.21511C11.4278 5.31894 11.4068 5.44423 11.4345 5.56352L11.5902 6.23681C11.6477 6.48508 11.8955 6.63991 12.1435 6.58245L12.6694 6.46085C12.8754 6.84757 13.1516 7.18467 13.4908 7.46294L13.252 7.96364C13.1991 8.07416 13.1927 8.20084 13.2335 8.3162C13.2746 8.43157 13.3595 8.52617 13.4698 8.57878L14.0932 8.8762C14.1581 8.90666 14.2257 8.92142 14.2923 8.92142ZM14.3078 4.91492C14.3078 4.21372 14.8784 3.64357 15.5796 3.64357C16.2806 3.64357 16.8509 4.21395 16.8509 4.91492C16.8509 5.61613 16.2806 6.18651 15.5796 6.18651C14.8782 6.18651 14.3078 5.61613 14.3078 4.91492Z"
          fill="currentColor"
          stroke="currentColor" />
        <path
          d="M21.6387 13.7937L21.1352 13.6822C21.1288 13.2297 21.0316 12.7925 20.8468 12.3827L21.2333 12.0701C21.3284 11.993 21.389 11.8813 21.4017 11.7595C21.4144 11.6377 21.378 11.5156 21.3009 11.4208L20.866 10.8838C20.7056 10.6856 20.4146 10.6554 20.2169 10.8158L19.8447 11.1173C19.4857 10.8421 19.0833 10.6471 18.6477 10.5375V10.067C18.6477 9.8123 18.4409 9.60556 18.1862 9.60556H17.4954C17.2407 9.60556 17.0339 9.8123 17.0339 10.067V10.538C16.5888 10.6499 16.1788 10.8513 15.8154 11.136L15.4386 10.8365C15.3429 10.7602 15.2222 10.7253 15.099 10.7392C14.9774 10.7532 14.8662 10.8144 14.79 10.9104L14.3602 11.451C14.2014 11.6503 14.2347 11.9408 14.434 12.0998L14.8242 12.4099C14.6438 12.8218 14.5503 13.2593 14.5475 13.7106L14.0427 13.8271C13.9234 13.8548 13.82 13.9282 13.7552 14.0325C13.6906 14.1361 13.6694 14.2614 13.6971 14.3809L13.8528 15.0542C13.9103 15.3024 14.159 15.4566 14.4059 15.3998L14.9322 15.2784C15.1382 15.6654 15.4144 16.0025 15.7531 16.2805L15.5146 16.7808C15.4617 16.8913 15.455 17.0182 15.4959 17.1336C15.5372 17.2489 15.6218 17.3433 15.7321 17.3961L16.3556 17.6936C16.4195 17.724 16.4873 17.7385 16.5538 17.7385C16.7259 17.7385 16.8916 17.6416 16.9707 17.4755L17.2139 16.9663C17.6357 17.0477 18.0766 17.0463 18.4949 16.9614L18.7421 17.4674C18.8537 17.6963 19.1302 17.7918 19.3593 17.6802L19.9799 17.3774C20.09 17.3239 20.1742 17.2286 20.2139 17.113C20.2538 16.9974 20.2464 16.8705 20.1924 16.7605L19.9497 16.2632C20.2864 15.9829 20.56 15.643 20.7628 15.2533L21.2884 15.37C21.4084 15.3966 21.5332 15.3747 21.6364 15.3084C21.7395 15.2427 21.8124 15.1388 21.839 15.0191L21.9882 14.3446C22.0448 14.0952 21.8876 13.849 21.6387 13.7937ZM19.1135 13.732C19.1135 14.4333 18.5432 15.0036 17.8424 15.0036C17.1415 15.0036 16.5706 14.4333 16.5706 13.732C16.5706 13.0308 17.1415 12.4605 17.8424 12.4605C18.5432 12.4607 19.1135 13.0311 19.1135 13.732Z"
          fill="currentColor"
          stroke="currentColor" />
      </svg>
    </div>
  </div>
  <span
    class="font-semibold"
    [ngClass]="{
      'text-[#0D2A4A]': activePanel !== 'automation',
      'text-[#042862]': activePanel === 'automation'
    }">
    Automation
  </span>
</div>
