import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LoginFormComponent } from './components/login-form/login-form.component'
import { LoginPageComponent } from './pages/login-page/login-page.component'
import { ReactiveFormsModule } from '@angular/forms'
import { UiModule } from '../ui/ui.module'
import { RouterModule } from '@angular/router'

@NgModule({
  declarations: [LoginFormComponent, LoginPageComponent],
  imports: [CommonModule, ReactiveFormsModule, UiModule, RouterModule],
})
export class AuthModule {}
