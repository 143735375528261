import { Component, OnInit } from '@angular/core'
import { Event, NavigationEnd, RouteConfigLoadStart, Router } from '@angular/router'
import { AuthService } from 'src/app/auth/services/auth/auth.service'
import { animations } from 'src/app/ui/animations'

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
  animations: [animations.fadeInOut],
})
export class SidebarLayoutComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  protected showSidebar = true
  protected loading = false

  private XL = 1280

  ngOnInit(): void {
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler.bind(this))

    this.router.events.subscribe(event => this.navigationHandler(event))

    this.authService.updateUserInformation().subscribe(account => {
      if (!account.isEmailVerified) {
        this.router.navigate(['/verify-email'])
      }
    })
  }

  private resizeHandler() {
    const width = window.innerWidth
    const shouldHideSidebar = width < this.XL

    this.showSidebar = !shouldHideSidebar
  }

  private navigationHandler(event: Event) {
    if (event instanceof RouteConfigLoadStart) {
      this.loading = true
    }

    if (event instanceof NavigationEnd) {
      this.loading = false
    }
  }
}
