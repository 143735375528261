<ui-card
  *ngIf="showDialog"
  [@enterFromBottom]
  (@enterFromBottom.done)="attemptCloseDialog()"
  class="p-6 max-w-sm w-screen h-[30rem] max-h-screen relative pt-12">
  <div class="top-2 right-2 absolute">
    <button
      class="text-xl flex items-center gap-2 p-1 rounded-full hover:bg-neutral-100 transition-colors"
      (click)="closeOverlay()">
      <ng-icon name="heroXMark" class="w-4 h-4" />
    </button>
  </div>
  <div class="flex flex-col h-full w-full relative">
    <ui-stepper class="w-full shrink-0" [steps]="2" [currentStep]="currentStep" />
    <div class="mt-8 mb-4 px-2 grow block overflow-y-auto">
      <h1 class="text-2xl font-extrabold text-darker mb-2">Create with AI</h1>
      @switch (currentStep) { @case(0) {
      <div [@delayedAbsFadeInOutFromRight]>
        <p class="text-sm text-darker mb-6">
          We'll do the heavy lifting. Just provide a few details and our AI will take care of the rest.
        </p>
        <app-ai-creation-provide-video-layout (currentVideoUrl)="videoUrl = $event" />
      </div>
      } @case(1) {
      <div [@delayedAbsFadeInOutFromRight] class="mt-4">
        <app-ai-creation-video-options-layout
          (validityChanged)="creationValid = $event"
          (aiOptions)="aiOptions = $event" />
      </div>
      } }
    </div>
    <div class="text-right shrink-0 relative h-6">
      <button
        *ngIf="currentStep < 1; else finishButton"
        class="btn button-primary text-sm flex items-center justify-center gap-2 absolute -right-2 -bottom-2 w-20"
        (click)="attemptNextStep()"
        [disabled]="!canProceedToNextStep()">
        Next
      </button>
      <ng-template #finishButton>
        <div class="absolute -right-2 -bottom-2">
          <button
            class="btn button-primary text-sm flex items-center justify-center gap-2 relative w-20"
            (click)="createVideos()"
            [disabled]="!canProceedToNextStep() || loading">
            <span [ngClass]="{ hidden: !loading }" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <svg
                class="animate-spin h-4 w-4 text-dark opacity-50"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </span>
            <span [ngClass]="{ 'opacity-0': loading }"> Create </span>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</ui-card>
