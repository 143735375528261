<div class="flex items-center gap-4">
  <div class="w-9 h-9 rounded-full items-center justify-center flex bg-[#DFE4EC]">
    <!-- attribution to https://www.svgrepo.com/ -->
    <div
      class="w-5 h-5 transition-colors"
      [ngClass]="{
        'text-brand': activePanel === 'statistics',
        'text-[#4D6080]': activePanel !== 'statistics'
      }">
      <svg
        viewBox="-3 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
        fill="currentColor"
        stroke="currentColor">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <title>stats</title>
          <desc>Created with Sketch Beta.</desc>
          <defs></defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
            <g
              id="Icon-Set-Filled"
              sketch:type="MSLayerGroup"
              transform="translate(-314.000000, -673.000000)"
              fill="currentColor">
              <path
                d="M328,673 L326,673 C324.896,673 324,673.896 324,675 L324,703 C324,704.104 324.896,705 326,705 L328,705 C329.104,705 330,704.104 330,703 L330,675 C330,673.896 329.104,673 328,673 L328,673 Z M338,689 L336,689 C334.896,689 334,689.896 334,691 L334,703 C334,704.104 334.896,705 336,705 L338,705 C339.104,705 340,704.104 340,703 L340,691 C340,689.896 339.104,689 338,689 L338,689 Z M318,682 L316,682 C314.896,682 314,682.896 314,684 L314,703 C314,704.104 314.896,705 316,705 L318,705 C319.104,705 320,704.104 320,703 L320,684 C320,682.896 319.104,682 318,682 L318,682 Z"
                id="stats"
                sketch:type="MSShapeGroup"></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
  <span
    class="font-semibold"
    [ngClass]="{
      'text-[#0D2A4A]': activePanel !== 'statistics',
      'text-[#042862]': activePanel === 'statistics'
    }">
    Statistics
  </span>
</div>
