import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router'
import { AuthService } from 'src/app/auth/services/auth/auth.service'

export type SidebarPanel = 'dashboard' | 'videos' | 'automation' | 'statistics' | 'settings' | 'admin'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slideIn', [
      state('void', style({ transform: 'translateX(-100%)' })),
      state('*', style({ transform: 'translateX(0)' })),
      transition(':enter', animate('0.2s ease-out')),
      transition(':leave', animate('0.05s linear')),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  protected activePanel: SidebarPanel | null = null

  @Output()
  public navigate = new EventEmitter<void>()

  @Input()
  public animationEnabled = false
  public sidebarShouldBeOpen = true

  @Input()
  public showCloseButton = false

  @Output()
  public sidebarClosed = new EventEmitter<void>()

  public closeSidebar() {
    this.sidebarShouldBeOpen = false
  }

  protected onAnimateDone(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.sidebarClosed.emit()
    }
  }

  ngOnInit(): void {
    this.activePanel = this.getActivePanelFromUrl(this.router.url)
    this.router.events.subscribe(url => {
      if (!(url instanceof NavigationEnd)) {
        return
      }

      this.activePanel = this.getActivePanelFromUrl(url.urlAfterRedirects || url.url)
    })
  }

  private getActivePanelFromUrl(url: string): 'dashboard' | 'videos' | 'automation' | 'statistics' | 'settings' | null {
    const urlParts = url.split('/')

    if (urlParts.length < 2) {
      return null
    }

    switch (urlParts[1]) {
      case '':
        return 'dashboard'

      case 'video':
        return 'videos'

      case 'automation':
        return 'automation'

      case 'statistics':
        return 'statistics'

      case 'settings':
        return 'settings'

      default:
        return null
    }
  }

  protected isAdmin(): boolean {
    return this.authService.cachedAccountInformation?.isStaff || false
  }
}
