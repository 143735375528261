import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AutomationDashboardPageComponent } from './pages/automation-dashboard-page/automation-dashboard-page.component'
import { UiModule } from '../ui/ui.module'

@NgModule({
  declarations: [AutomationDashboardPageComponent],
  imports: [CommonModule, UiModule],
})
export class AutomationModule {}
