import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core'
import { Observable, catchError, timer } from 'rxjs'
import { AuthService } from 'src/app/auth/services/auth/auth.service'
import { MinimalOrganisation, Organisation } from 'src/app/main/interfaces/organisation.interface'
import { PaginatedResponse } from 'src/app/main/interfaces/paginated-response'
import { OrganisationService } from 'src/app/main/services/organisation/organisation.service'
import { animations } from 'src/app/ui/animations'
import { CreateOrganisationOverlayComponent } from '../../create-organisation-overlay/create-organisation-overlay.component'

@Component({
  selector: 'app-organisations-selector',
  templateUrl: './organisations-selector.component.html',
  styleUrls: ['./organisations-selector.component.scss'],
  animations: [animations.fadeInOutFromTop],
})
export class OrganisationsSelectorComponent {
  private checkedOrganisations: string[] = []
  constructor(
    private organisationService: OrganisationService,
    private overlay: Overlay
  ) {}

  @ViewChild('selectorButton') selectorButton: ElementRef<HTMLButtonElement> | null = null

  @Output()
  public navigated = new EventEmitter<void>()

  protected organisations$ = this.organisationService.getOrganisations()
  protected activeOrganisation: MinimalOrganisation | null = null
  protected organisationsSelectorOpened = false

  protected activeOrganisation$ = this.organisationService.getActiveOrganisation()

  private overlayRef: OverlayRef | null = null

  protected getMaxMembers(organisation: MinimalOrganisation | null) {
    return this.organisationService.getMaxMembers(organisation)
  }

  protected getActiveOrganisationOrPrivateWorkspaceName(organisations: PaginatedResponse<MinimalOrganisation>) {
    if (!this.activeOrganisation) {
      return 'No workspace'
    }

    return this.activeOrganisation.name
  }

  protected setActiveOrganisation(organisation: MinimalOrganisation) {
    this.organisationService.setActiveOrganisation(organisation)
    this.organisationsSelectorOpened = false
    this.activeOrganisation = organisation
    this.organisations$ = this.organisationService.getOrganisations()
  }

  protected canCreateNewOrganisation(organisations: MinimalOrganisation[]) {
    return organisations.length < 3
  }

  protected openCreateOrganisationDialog() {
    if (this.overlayRef) {
      this.overlayRef.detach()
    }

    this.organisationsSelectorOpened = false

    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'blurred-bg',
    })

    const portal = new ComponentPortal(CreateOrganisationOverlayComponent)
    const componentRef = this.overlayRef.attach(portal)

    this.overlayRef.backdropClick().subscribe(() => {
      this.closeOverlay()
    })

    componentRef.instance.closeOverlay.subscribe((organisation: MinimalOrganisation | null) => {
      this.closeOverlay()
      if (organisation) {
        this.setActiveOrganisation(organisation)
      }
    })

    this.overlayRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.closeOverlay()
      }
    })
  }

  private closeOverlay() {
    this.overlayRef?.detach()
  }

  protected canInviteMembers(organisation: Organisation) {
    return this.organisationService.canInviteMembers(organisation)
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.organisationsSelectorOpened) {
      this.organisationsSelectorOpened = false
      this.selectorButton?.nativeElement.focus()
    }
  }
}
